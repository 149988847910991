<template>
    <div class="bg-stone-100 dark:bg-stone-950 fixed w-full h-full z-[-1]"></div>
    <div class="pattern fixed w-full h-full opacity-[1%] z-[-1] dark:"></div>
    <div class="absolute top-0 left-0 right-0 bottom-0 text-stone-700 dark:text-white">
        <div class="min-h-[100vh]">
            <NuxtPage />
        </div>
        <div class="fixed top-6 left-6 opacity-60 hover:opacity-100">
            <UTooltip :openDelay="500" :popper="{ placement: 'right' }" :text="colorMode.value === 'dark' ? 'Helles Erscheinungsbild nutzen' : 'Zur dunklen Seite wechseln'">
                <UIcon @click="isDark = true" name="i-mdi-theme-light-dark" class="w-8 h-8 cursor-pointer duration-500 -mr-4 transition-all ease-[cubic-bezier(.75,-0.5,0,1.75)]" :class="`${isDark ? 'rotate-180' : ''}`"/>
            </UTooltip>
        </div>
        <div class="text-center">
            <!-- <UDivider :ui="{ border: 'border-gray-200/40 dark:border-gray-800/40'}"> -->
            <img class="inline-block w-auto h-12 mb-8 opacity-40 hover:opacity-100 tramsition duration-500 grayscale hover:grayscale-0" src="~/assets/img/logo.svg" alt="">
            <!-- </UDivider> -->
            <p class="pb-12 text-black dark:text-white">
                <strong class="font-bold text-lg">eijns</strong> - 
                <span class="text-lg font-light">Enhanced Information and<br class="lg:hidden"> Joint Notification System</span>
            </p>
        </div>
    </div>
</template>
<script setup>

    const colorMode = useColorMode()
    const isDark = computed({
        get () {
            return colorMode.value === 'dark'
        },
        set () {
            colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
        }
    })

</script>
<style type="text/css">

.patterns {
  --stripe: #cfd8dc11;
  --bg: #e1e1e102;

  background: linear-gradient(135deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, var(--bg) 25%, transparent 25%),
    linear-gradient(45deg, var(--bg) 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: var(--stripe);
}
.pattern {
  --s: 150px; /* control the size */
  --c1: #f7d2a1;
  --c2: #05057e;

  --_g: 
     var(--c1) 0%  5% ,var(--c2) 6%  15%,var(--c1) 16% 25%,var(--c2) 26% 35%,var(--c1) 36% 45%,
     var(--c2) 46% 55%,var(--c1) 56% 65%,var(--c2) 66% 75%,var(--c1) 76% 85%,var(--c2) 86% 95%,
     #0000 96%;
  background:
    radial-gradient(closest-side at 100% 0, var(--_g)),
    radial-gradient(closest-side at 0 100%, var(--_g)),
    radial-gradient(closest-side, var(--_g)),
    radial-gradient(closest-side, var(--_g)) calc(var(--s)/2) calc(var(--s)/2)
    var(--c1);
  background-size: var(--s) var(--s);
}

</style>